
.Content {

  &__alert {
    background: var(--color-yellow);

    &Title {
      color: var(--color-text-primary);
    }

    &Message {
      color: var(--color-text-primary) !important;

      * {
        color: var(--color-text-primary) !important;
      }
    }
  }

  hr {
    background: url("~@amf/shared/assets/icon-cross-blue.svg") no-repeat center;
    background-size: 24px 24px;
  }
}
