
.Navigation {

  &__brand {
    width: 118px;
    min-width: 118px;
    position: relative;
    left: -7px;

    @media (max-width: $break-small) {
      width: 79px;
      min-width: 79px;
      height: 40px;
      left: -2px;
    }
  }

  &__menuItem--selected:after {
    background-image: url("~@amf/shared/assets/icon-cross-blue.svg") !important;
  }

  &--white &__menuItem:before {
    background-image: url("~@amf/shared/assets/icon-menu-arrow-blue.svg") !important;
  }

  &--white &__submenu {
    background-color: var(--color-blue-20);
  }

  &--dropdown.Navigation--white .Navigation__submenu {
    background-color: inherit;
  }

  &--white &__submenuItem {
    color: var(--color-white);
  }

  &--white &__menuItem {
    color: var(--color-text-primary);
  }

  &--white &__menuItem--selected {
    color: var(--color-blue-20);
  }
}
